<div class="container">
  <hr />
  <div class="mb-3 d-flex">
    <div class="flex-grow-1">
      <label for="packing-list-input" style="cursor: pointer"
        class="text-center mb-0 py-3 w-100 block bg-primary text-white rounded"
        [ngClass]="booking?.packagingList ? 'bg-primary pe-none' : 'bg-secondary'">Packing
        List <em *ngIf="booking?.packagingList"><small>(Uploaded)</small></em></label>
      <input id="packing-list-input" type="file" (change)="selectImage($event, 'PACKAGING')" class="d-none"
        #packingList />
    </div>
    <div class="align-middle px-3 m-auto">
      <p class="m-0 mr-2 d-inline align-middle">
        <a href="javascript:void(0)" (click)="downloadDocument(booking?.packagingList, 'packaging-list')"
          [ngClass]="booking?.packagingList ? '' : 'pe-none text-secondary opacity-50'">
          <i class="fa fa-download" aria-hidden="true" style="font-size: 17px"></i>
        </a>
      </p>
      <p class="mt-2 mb-2 d-inline align-middle">
        <a href="javascript:void(0)" (click)="packingList.click()"
          [ngClass]="booking?.packagingList ? 'text-success' : 'pe-none text-secondary opacity-50'">
          <i class="fas fa-pen" aria-hidden="true" style="font-size: 17px;cursor: pointer;"></i>
        </a>
      </p>
    </div>
  </div>
  <div class="mb-3 d-flex">
    <div class="flex-grow-1">
      <label for="commercial-invoice-input" style="cursor: pointer"
        class="text-center mb-0 py-3 w-100 block bg-primary text-white rounded"
        [ngClass]="booking?.commercialInvoice ? 'bg-primary pe-none' : 'bg-secondary'">Commercial
        Invoice <em *ngIf="booking?.commercialInvoice"><small>(Uploaded)</small></em></label>
      <input id="commercial-invoice-input" type="file" (change)="selectImage($event, 'INVOICE')" class="d-none"
        #commercialInvoice />
    </div>
    <div class="align-middle px-3 m-auto">
      <p class="m-0 mr-2 d-inline align-middle">
        <a href="javascript:void(0)" (click)="downloadDocument(booking?.commercialInvoice, 'commercial-invoice')"
          [ngClass]="booking?.commercialInvoice ? '' : 'pe-none text-secondary opacity-50'">
          <i class="fa fa-download" aria-hidden="true" style="font-size: 17px"></i>
        </a>
      </p>
      <p class="mt-2 mb-2 d-inline align-middle">
        <a href="javascript:void(0)" (click)="commercialInvoice.click()"
          [ngClass]="booking?.commercialInvoice ? 'text-success' : 'pe-none text-secondary opacity-50'">
          <i class="fas fa-pen" aria-hidden="true" style="font-size: 17px;cursor: pointer;"></i>
        </a>
      </p>
    </div>
  </div>
  <div class="mb-3 d-flex">
    <div class="flex-grow-1">
      <label for="bill-lading-input" style="cursor: pointer"
        class="text-center mb-0 py-3 w-100 block bg-primary text-white rounded"
        [ngClass]="booking?.billOfLading ? 'bg-primary pe-none' : 'bg-secondary'">Bill of
        Lading <em *ngIf="booking?.billOfLading"><small>(Uploaded)</small></em></label>
      <input id="bill-lading-input" type="file" (change)="selectImage($event, 'BILL')" class="d-none" #billLading />
    </div>
    <div class="align-middle px-3 m-auto">
      <p class="m-0 mr-2 d-inline align-middle">
        <a href="javascript:void(0)" (click)="downloadDocument(booking?.billOfLading, 'bill-of-lading')"
          [ngClass]="booking?.billOfLading ? '' : 'pe-none text-secondary opacity-50'">
          <i class="fa fa-download" aria-hidden="true" style="font-size: 17px"></i>
        </a>
      </p>
      <p class="mt-2 mb-2 d-inline align-middle">
        <a href="javascript:void(0)" (click)="billLading.click()"
          [ngClass]="booking?.billOfLading ? 'text-success' : 'pe-none text-secondary opacity-50'">
          <i class="fas fa-pen" aria-hidden="true" style="font-size: 17px;cursor: pointer;"></i>
        </a>
      </p>
    </div>
  </div>
  <hr />
  <button type="button" class="btn btn-secondary float-right" (click)="closeModel()">Close</button>
</div>

<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Uploading...</p>
</ngx-spinner>