<div class="wrapper" style="margin-top: 0px">
    <div class="table2" style="margin-bottom: 10px">
        <div class="row2">
            <!-- <div class="cell2 d-flex text-left" style="box-shadow: none">
            </div> -->
            <div class="cell2"></div>
            <div class="cell2"></div>
            <div class="cell2 myCell pl-lg-2 mt-2">
                <div class="form-group">
                    <span class="fa fa-search form-control-icon"></span>
                    <input type="text" class="form-control" [(ngModel)]="filteredStatus"
                        placeholder="Search Customer" />
                </div>
            </div>
        </div>
    </div>

    <table class="table text-center" style="box-shadow: none">
        <thead>
            <tr>
                <th scope="col">S.No</th>
                <th scope="col">Photo</th>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Info</th>
                <th scope="col">Visited Location</th>
            </tr>
        </thead>
        <tbody style="color: gray; font-weight: 600">
            <tr *ngFor="let customer of customers
                | nameFilter: filteredStatus
                | paginate: { itemsPerPage: 10, currentPage: p }; let i = index">
                <th scope="row">{{ i + 1 }}</th>
                <td>
                    <img [src]="customer.imageUrl" onerror="this.src='./../../../assets/profile.png'" height="36px"
                        width="36px" alt="" style="border-radius: 50%;" />
                </td>
                <td>{{ customer.name }}</td>
                <td>{{ customer.user_email }}</td>
                <td>
                    <a routerLink="javascript:void(0)" (click)="previewCustomer(customer)">
                        <i class="fas fa-external-link-alt" aria-hidden="true" style="font-size: 17px"></i>
                    </a>
                </td>
                <td>
                    <a [routerLink]="'/admin/locations/visited/' + customer.uid">
                        <i class="fas fa-external-link-alt" aria-hidden="true" style="font-size: 17px"></i>
                    </a>
                </td>
            </tr>
        </tbody>
    </table>

    <!-- pagination -->
    <pagination-controls (pageChange)="p = $event"></pagination-controls>
</div>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Loading customer(s)...</p>
</ngx-spinner>