import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ContainerRoutingModule } from './container-routing.module';
import { ListComponent } from './list/list.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SaveComponent } from './save/save.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { ContainerInspectionViewComponent } from './container-inspection-view/container-inspection-view.component';


@NgModule({
  declarations: [ListComponent, SaveComponent, ContainerInspectionViewComponent],
  imports: [
    CommonModule,
    ContainerRoutingModule,
    NgxPaginationModule,
    NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule
  ]
})
export class ContainerModule { }
