import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CustomersComponent } from './customers.component';
import { AuthGuard } from 'src/app/services/auth.guard';


const routes: Routes = [
  {
    path: '', component: CustomersComponent,
    canActivate: [AuthGuard],
    data: { permission: 'userData' },
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CustomerRoutingModule { }
