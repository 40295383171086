import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BlogsService } from 'src/app/services/blogs.service';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.css']
})
export class BlogsComponent implements OnInit {
  filteredStatus = ""
  blogs = [];
  loadingText = "Loading blog(s)...";
  selectedBlog: any;

  constructor(
    private spinner: NgxSpinnerService,
    private blogsSvc: BlogsService,
    private toast: ToastrService,
  ) { }

  ngOnInit(): void {
    this.getBlogs();
  }

  getBlogs() {
    this.spinner.show();
    this.blogsSvc.getBlogList({}).subscribe((res: any) => {
      this.blogs = res.data;
      this.spinner.hide();
    });
  }

  setBlogId(id) {
    localStorage.setItem('blogId', id);
  }

  onDelete(blog: any) {
    this.selectedBlog = blog;
  }

  deleteBlog() {
    this.spinner.show();
    this.loadingText = "deleting blog..."
    this.blogsSvc.deleteBlog({ id: this.selectedBlog._id }).subscribe((res: any) => {
      if (res.status) {
        this.spinner.hide();
        this.loadingText = "Loading blog(s)...";
        this.toast.success(res.description);
        this.getBlogs();
      }
    }), (error) => {
      this.loadingText = "Loading blog(s)...";
    };
  }

}
