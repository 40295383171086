import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListComponent } from './list/list.component';
import { BookingRoutingModule } from './booking-routing.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SaveComponent } from './save/save.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SaveDocumentsComponent } from './save-documents/save-documents.component';

@NgModule({
  declarations: [ListComponent, SaveComponent, SaveDocumentsComponent],
  imports: [
    CommonModule,
    BookingRoutingModule,
    NgxPaginationModule,
    NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
  ],
})
export class BookingModule {}
