import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/services/auth/auth.service';
import { InspectorService } from 'src/app/services/inspector.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ListComponent implements OnInit {
  inspectors: any[];
  p: number = 1;
  filteredStatus = '';

  constructor(
    private inspectorService: InspectorService,
    private spinner: NgxSpinnerService,
    private authService: AuthService
  ) {
  }

  ngOnInit(): void {
    this.getInspectors();
  }

  getInspectors() {
    this.spinner.show();
    const params = {
      skip: 0,
      limit: 10,
      adminVerification: 'APPROVED',
    };

    this.inspectorService.getAllInspectors(params).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res.data) {
          res.data.map((inspector, index) => {
            this.readFile(inspector.imageUrl, index);
          });

          this.inspectors = res.data;
        }
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  setInspector(inspector) {
    localStorage.setItem('selectedInspector', JSON.stringify(inspector));
  }

  readFile(url, index) {
    this.authService.readFile(url).subscribe(
      (res) => {
        this.inspectors[index].imageUrl = res;
      },
      (error) => { }
    );
  }
}
