import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CustomerService } from 'src/app/services/customer.service';
import { PreviewComponent } from './preview/preview.component';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css']
})
export class CustomersComponent implements OnInit {

  customers: any[];
  p: number = 1;
  filteredStatus = '';

  constructor(
    public matDialog: MatDialog,
    private customerService: CustomerService,
    private spinner: NgxSpinnerService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.getCustomers();
  }

  getCustomers() {
    this.spinner.show();
    const params = {
      skip: 0,
      limit: 0
    };

    this.customerService.getCustomers(params).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res.data) {
          res.data.map((inspector, index) => {
            this.readFile(inspector.imageUrl, index);
          });

          this.customers = res.data;
        }
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  previewCustomer(customer) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = 'preview-customer-details';
    dialogConfig.height = "auto";
    dialogConfig.width = "600px";

    //passing data
    dialogConfig.data = { customer };

    const modalDialog = this.matDialog.open(PreviewComponent, dialogConfig);
  }

  readFile(url, index) {
    this.authService.readFile(url).subscribe(
      (res) => {
        this.customers[index].imageUrl = res;
      },
      (error) => { }
    );
  }

}
