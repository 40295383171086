<div class="wrapper" style="margin-top: 0px">
  <div class="table2" style="margin-bottom: 10px">
    <div class="row2">
      <div class="cell2 d-flex text-left" style="box-shadow: none">
        <h4>All Inspections</h4>
      </div>
      <div class="cell2"></div>
      <div class="cell2"></div>
      <div class="cell2 myCell pl-lg-2 mt-2">
        <div class="form-group">
          <span class="fa fa-search form-control-icon"></span>
          <input type="text" class="form-control" [(ngModel)]="filteredStatus" placeholder="Search Inspection" />
        </div>
      </div>
    </div>
  </div>

  <table class="table text-center" style="box-shadow: none">
    <thead>
      <tr>
        <th scope="col">S.No</th>
        <th scope="col">Photo</th>
        <th scope="col">Name Of Inspector</th>
        <th scope="col">Inspector Email</th>
        <th scope="col">View All Bookings</th>
      </tr>
    </thead>
    <tbody style="color: gray; font-weight: 600">
      <tr *ngFor="
          let inspector of inspectors
            | nameFilter: filteredStatus
            | paginate: { itemsPerPage: 10, currentPage: p };
          let i = index
        ">
        <th scope="row">{{ i + 1 }}</th>

        <td>
          <img [src]="inspector.imageUrl" onerror="this.src='./../../../assets/profile.png'" height="36px" width="36px"
            alt="" />
        </td>
        <td>{{ inspector.name }}</td>
        <td>{{ inspector.user_email }}</td>
        <td>
          <a routerLink="/admin/bookings" (click)="setInspector(inspector)">
            <i class="fas fa-external-link-alt" aria-hidden="true" style="font-size: 17px"></i>
          </a>
        </td>
      </tr>
    </tbody>
  </table>

  <!-- pagination -->
  <pagination-controls (pageChange)="p = $event"></pagination-controls>
</div>

<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Loading inspection(s)...</p>
</ngx-spinner>