import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ContainerService } from 'src/app/services/container.service';
import { SaveComponent } from '../save/save.component';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css'],
})
export class ListComponent implements OnInit {
  bookingNumber: any;
  inspector: any;
  containers: any[] = [];
  p: number = 1;

  constructor(
    public matDialog: MatDialog,
    private containerService: ContainerService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.spinner.show();
    this.bookingNumber = localStorage.getItem('bookingNumber');

    this.inspector = JSON.parse(localStorage.getItem('selectedInspector'));
    if (this.bookingNumber) {
      this.getAllContainers();
    }
  }

  getAllContainers() {
    const params = {
      bookingNumber: this.bookingNumber,
      admin: true,
    };

    this.containerService.getContainerList(params).subscribe(
      (res: any) => {
        if (res.status) {
          if (res.data) {
            this.containers = res.data;
          }
          this.spinner.hide();
        }
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  createNewCategory() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = 'save-container';
    dialogConfig.height = '308px';
    dialogConfig.width = '500px';
    //passing data
    dialogConfig.data = { bookingNumber: this.bookingNumber };

    this.matDialog.open(SaveComponent, dialogConfig);
    this.matDialog
      ._getAfterAllClosed()
      .subscribe((res: any) => this.getAllContainers());
  }

  setContainerId(container) {
    localStorage.setItem('containerId', container.containerId);
  }
}
