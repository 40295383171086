<div class="wrapper" style="margin-top: 0px">
    <div class="table2">
      <div class="row2">
        <div class="cell2 text-right" style="box-shadow: none">
          <h4>All Sub Admins</h4>
        </div>
        <div
          class="cell2 text-lg-right"
          style="box-shadow: none; border-radius: 12px"
        >
          <button
            style="background-color: #888979; border-radius: 8px; color: white"
            class="btn mb-1"
            (click)="createSubAdmin()"
          >
            <i class="fas fa-plus"></i> Create sub admin
          </button>
        </div>
      </div>
    </div>
  
    <table class="table text-center" style="box-shadow: none">
      <thead>
        <tr>
          <th scope="col">S.No</th>
          <th scope="col">Sub Admin ID</th>
          <th scope="col">Password</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody style="color: gray; font-weight: 600">
        <tr
          *ngFor="
            let admin of allSubAdmins
              | paginate: { itemsPerPage: 10, currentPage: p };
            let i = index
          "
        >
          <th scope="row">{{ i + 1 }}</th>
          <td>
            {{ admin?.user_email }}
          </td>
          <td>*********</td>
          <td>
            <div *ngIf="admin._id !== currentUser?._id">
              <a
                class="mr-4 text-success"
                href="javascript:void(0)"
                (click)="editSubAdmin(admin)"
                ><i
                  class="fas fa-pen"
                  aria-hidden="true"
                  style="font-size: 17px; color: darkgreen; cursor: pointer"
                ></i
              ></a>
              <a
                class="text-danger"
                href="javascript:void(0)"
                data-toggle="modal"
                data-target="#exampleModalCenter"
                (click)="setDeleteAdmin(admin)"
                *ngIf="admin._id !== currentUser?._id"
                ><i
                  class="fas fa-trash"
                  aria-hidden="true"
                  style="
                    font-size: 17px;
                    color: rgb(192, 31, 31);
                    cursor: pointer;
                  "
                ></i
              ></a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  
    <!-- pagination -->
    <pagination-controls (pageChange)="p = $event"></pagination-controls>
  
    <ngx-spinner
      bdColor="rgba(51,51,51,0.8)"
      size="medium"
      color="#fff"
      type="ball-scale-multiple"
    >
      <p style="font-size: 20px; color: white">{{ loaderText }}</p>
    </ngx-spinner>
  </div>
  
  <!-- Confirmation Modal -->
  <div
    class="modal fade"
    id="exampleModalCenter"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Confirmation</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">Are you sure to delete this sub admin?</div>
        <div class="modal-footer">
          <button
            type="button"
            id="closeModal"
            class="btn btn-secondary"
            data-dismiss="modal"
          >
            No
          </button>
          <button type="button" class="btn btn-danger" (click)="deleteSubAdmin()">
            Yes
          </button>
        </div>
      </div>
    </div>
  </div>
  