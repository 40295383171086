<div class="wrapper mt-4">
    <div class="container">
        <div class="row">
            <h4>Save blog</h4>
        </div>

        <form [formGroup]="blogForm" (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="col">
                    <div class="form-group mt-4">
                        <label for="name">Written by:</label>
                        <input type="text" class="form-control" id="writtenBy" formControlName="writtenBy" />
                        <div *ngIf="(formSubmitted || blogForm.controls.writtenBy.touched) && blogForm.controls.writtenBy.errors?.required"
                            class="error">
                            *Written by is required!
                        </div>
                    </div>

                    <div class="form-group mt-4">
                        <label for="title">Title:</label>
                        <input type="text" class="form-control" id="title" formControlName="title"/>
                        <div *ngIf="(formSubmitted || blogForm.controls.title.touched) && blogForm.controls.title.errors?.required"
                            class="error">
                            *Title is required!
                        </div>
                    </div>
                    <div class="form-group mt-4">
                        <label for="shortDescription">Short description:</label>
                        <textarea rows="4" class="form-control" id="shortDescription" formControlName="short_description"></textarea>
                        <div *ngIf="(formSubmitted || blogForm.controls.short_description.touched) && blogForm.controls.short_description.errors?.required"
                            class="error">
                            *Short description is required!
                        </div>
                    </div>
                    <div class="form-group mt-4">
                        <label for="description">Content:</label>
                        <quill-editor [styles]="{height: '200px'}" [modules]="quillConfig" [(ngModel)]="editorText" formControlName="content"
                            [preserveWhitespace]="false" name="description">
                        </quill-editor>
                        <div *ngIf="(formSubmitted || blogForm.controls.content.touched) && blogForm.controls.content.errors?.required"
                            class="error">
                            *Content is required!
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group row mt-4 justify-content-center">
                <div class="text-center">
                  <button type="submit" class="btn" style="
                      background-color: #0065ff;
                      width: 200px;
                      border-radius: 10px;
                      color: white;
                    " [disabled]="!blogForm.valid">
                    Save
                  </button>
                </div>
              </div>
        </form>
    </div>
</div>

<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>