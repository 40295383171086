import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { InspectionService } from 'src/app/services/inspection.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SaveDocumentsComponent } from '../save-documents/save-documents.component';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ListComponent implements OnInit {
  inspector: any;
  bookings: any[] = [];
  p: number = 1;
  loadingText = 'Loading booking(s)...';
  isDocumentPopup = false;

  constructor(
    public matDialog: MatDialog,
    private inspectionService: InspectionService,
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    private clipboard: Clipboard,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.inspector = JSON.parse(localStorage.getItem('selectedInspector'));

    if (this.inspector) {
      this.getBookings();
    }
  }

  getBookings() {
    this.spinner.show();
    const params = {
      assigned_to: this.inspector.user_email,
      skip: 0,
      limit: 20,
    };

    this.inspectionService.getInspection(params).subscribe(
      (res: any) => {
        if (res.status) {
          this.spinner.hide();
          if (res.data) {
            res.data.map((item, index) => {
              if (item.packagingList !== 'Not Updated') {
                item.packagingList = this.readDocumentsFile(item.packagingList, index, 'packagingList');
              }
              if (item.commercialInvoice !== 'Not Updated') {
                item.commercialInvoice = this.readDocumentsFile(item.commercialInvoice, index, 'commercialInvoice');
              }
              if (item.billOfLading !== 'Not Updated') {
                item.billOfLading = this.readDocumentsFile(item.billOfLading, index, 'billOfLading');
              }
            });
            this.bookings = res.data;
          }
        }
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  setBookingNumber(booking) {
    localStorage.setItem('bookingNumber', booking.bookingNumber);
  }

  onShareClick(bookingNumber) {
    this.loadingText = 'Generating URL...';
    this.spinner.show();
    this.inspectionService.shareInspection({ bookingNumber }).subscribe(
      (res: any) => {
        if (res.status) {
          this.clipboard.copy(res.data);
          this.toast.success('Copied to clipboard!', 'Success', {
            timeOut: 2500,
            progressBar: true,
            progressAnimation: 'increasing',
            positionClass: 'toast-top-right',
          });
          this.spinner.hide();
        }
      },
      (errors: any) => {
        this.toast.error(errors.error.description, 'Error Occured', {
          timeOut: 2500,
          progressBar: true,
          progressAnimation: 'increasing',
          positionClass: 'toast-top-right',
        });
        this.spinner.hide();
      }
    );
  }

  showDocumentUploadForm(booking) {
    this.isDocumentPopup = true;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = 'save-container';
    dialogConfig.height = '355px';
    dialogConfig.width = '600px';
    //passing data
    dialogConfig.data = { booking };

    this.matDialog.open(SaveDocumentsComponent, dialogConfig);
    this.matDialog
      ._getAfterAllClosed()
      .subscribe((res: any) => {
        this.getBookings();
        this.isDocumentPopup = false;
      });
  }

  readDocumentsFile(url, index, key) {
    this.authService.getFileBlob(url).subscribe(
      (res) => {
        this.bookings[index][key] = res;
      },
      (error) => { }
    );
  }
}
