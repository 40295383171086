import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProfileRoutingModule } from './profile-routing.module';
import { ProfileComponent } from './profile.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { FormsModule } from '@angular/forms';
import { EditPasswordComponent } from './edit-password/edit-password.component';
import { NgxSpinnerModule } from 'ngx-spinner';


@NgModule({
  declarations: [ProfileComponent, EditProfileComponent, EditPasswordComponent],
  imports: [
    CommonModule,
    ProfileRoutingModule,
    FormsModule,
    NgxSpinnerModule
  ]
})
export class ProfileModule { }
