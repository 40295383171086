import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class InspectionService {
  apiPrefix: string;
  headers = new HttpHeaders();

  constructor(private http: HttpClient) {
    this.apiPrefix = environment.baseUrl + 'api/v1/inspection/';
    this.headers = this.headers.set(
      'x-access-token',
      localStorage.getItem('adventouresAdminAuthToken')
    );
  }

  getInspection(params) {
    return this.http.post(this.apiPrefix + 'get-admin', params, {
      headers: this.headers,
    });
  }

  createInspection(params) {
    return this.http.post(this.apiPrefix + 'create', params, {
      headers: this.headers,
    });
  }

  shareInspection(params) {
    return this.http.post(this.apiPrefix + 'share', params, {
      headers: this.headers,
    });
  }

  getSharedInspection(params) {
    let headers = new HttpHeaders();
    headers = headers.set(
      'x-access-token',
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2VtYWlsIjoidGVzdEBnbWFpbC5jb20iLCJpYXQiOjE2NjI1Njc0OTF9.mNZ5Mh-KVbd_z2ieoesgkRHYvRykdQkuW4bPc_ZaYiA'
    );
    return this.http.post(this.apiPrefix + 'share-data', params, {
      headers: headers,
    });
  }

  uploadInspectionDocument(params) {
    return this.http.post(this.apiPrefix + 'uploadImage', params, {
      headers: this.headers,
    });
  }
}
