import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NameFilterPipe } from '../pipes/name-filter/name-filter.pipe';



@NgModule({
  declarations: [NameFilterPipe],
  imports: [
    CommonModule
  ],
  exports: [NameFilterPipe]
})
export class SharedModule { }
